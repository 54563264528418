//
//
//
//
//
//
//
//
//

  export default {
    props:['cuisine'],
    data() {
      return {
        IfActive: true,
        shopList:[]
      }
    },
    /* mounted() {
      this.Bus.$on('cuisine', res => {
            let shoplist =  res
            let cateList = []
            shoplist.forEach((item)=>{
              cateList.push(item)
            })
            this.unique3(cateList)
            
      });   
    }, */ 
    methods:{
      /* unique3(Array){
        for(var i=0 ,length = res.length; i<length; i++){
          if(res[i].txt=="其他"){
            res.splice(i, 1);
            break;
          }
        }
        this.shopList = res
        this.shopList.push("其他")
      },  */
      changeActive(e){
        let target = e.target

        if(target.tagName.toLowerCase()=="li"){
          for(var i=0;i<target.parentNode.children.length;i++){
              target.parentNode.children[i].className=""
          }
          target.className="active"
        }
      },
    },
    components: {}
  }
