//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

	import normalHead from '@/views/components/ishop/header.vue'
	import screenFilter from '@/views/components/ishop/screenFilter'
	import restaurantList from '@/views/components/ishop/shopList'
	import myCart from '@/views/components/ishop/dialogs/myCart'
	import { mapMutations } from 'vuex'
	import { miceService } from '@/service/miceService'
	export default {
		props: {
			cartShops: {
				type: Object,
				default: {}
			},
			fromMyShops: {
				type: Boolean,
				default: false
			}
		},
		name: 'step2CustomShop',
		data() {
			return {
				popupRecommend: false,
				headValue: {
					name: '自定义餐厅',
					haveBack: true,
					haveRule: true,
				},
				cart: { //推荐餐厅信息
					groom: true,
					select: 1,
					sum: 1,
					itemType: 5,
					tag: '推荐餐厅',
					shops: [{
						shopId: null,
						"rowId": 0,
						"recommendType": 2,
						"name": "",
						"imgThumbnail": "",
						"priceAvgVal": 0,
						"regionVal": "",
						"categoryVal": "",
						"tel": "",
						"address": "",
						"checked": false,
					}]
				},
				typeNum: this.$store.state.ishop.maxQty5Type,
				myShops: [],
				isSubmitData: false
			}
		},
		created() {
			this.Bus.$on('openPropu', openPropu => {
				this.cart.shops = [{
						shopId: null,
						"rowId": 0,
						"recommendType": 2,
						"name": "",
						"imgThumbnail": "",
						"priceAvgVal": 0,
						"regionVal": "",
						"categoryVal": "",
						"tel": "",
						"address": "",
						"checked": false,
					}]
				this.popupRecommend = openPropu
				this.messageBox({
					title: '餐厅上线基本规则',
					// message: `1、距离医院3km内<br/>2、能够开具正规发票<br/>3、同意发票暂存在餐厅<br/>4、有包房或独立隔断区域<br/>5、仅接受机打水单或小票`,
					message: this.$store.state.ishop.shopRuleMsg,
					confirmButtonText: '已知晓'
				});
			});
			// this.queryMyShops() 
		},
		methods: {
			...mapMutations([
				'changeisDisable'
			]),
			//查看我的推荐餐厅
			// changePropu() {
			// 	var changePopup = true
			// 	this.Bus.$emit('changePopup', changePopup);
			// },
			//提交保存手工录入的餐厅
			postMyshops: async function() {
				let params = {
					cityId: sessionStorage.getItem('pingRegionId'),
					hospitalId: sessionStorage.getItem('hospitalId'),
					itemType: this.cart.itemType,
					shops: this.cart.shops,
					channel: localStorage.getItem('channel') || sessionStorage.getItem('channel')
				}
				let pass = localStorage.getItem('channel') || sessionStorage.getItem('channel')
				const res2 = await miceService.postMyShops(params)
				let res = res2.content
				if(res) {
					this.isSubmitData = false
				}
				if(res.status) {
					if(pass == 'app' && this.$store.state.ishop.meesageRule) {
						this.messageBox({
							message: this.$store.state.ishop.meesageRule,
							confirmButtonText: '确定',
							closeOnClickModal: false
						}).then(action => {
							//只有手工录入、直接从myShops弹出此popup
							if(this.fromMyShops) {
								this.changeisDisable(false)
								this.Bus.$emit('openPropu', false); // 或者 this.popupRecommend = false;
								this.$emit('queryMyShops')
							} else { // 同时存在选择餐厅和手工录入
								this.$router.push({
									path: '/myShops'
								})
							}
						})
					} else {
						//只有手工录入、直接从myShops弹出此popup
						if(this.fromMyShops) {
							this.changeisDisable(false)
							this.Bus.$emit('openPropu', false); // 或者 this.popupRecommend = false;
							this.$emit('queryMyShops')
						} else { // 同时存在选择餐厅和手工录入
							this.$router.push({
								path: '/myShops'
							})
						}
					}
				}else{
					this.toast(res.msg);
				}
			},
			//提交手工录入的餐厅
			submitData: function() {
				if(this.$store.state.ishop.maxQty5Type == 0 || !this.$store.state.ishop.maxQty5Type){
					this.toast({
						message: '最多可推荐0家餐厅，不可提交',
						duration: 5000,
					});
					return false;
				}
				if(this.isSubmitData) {
					this.toast({
						message: '正在提交ing..请勿重复操作',
						duration: 2000,
					});
					return;
				}
				this.changeisDisable(false)
				this.postMyshops()
			}
		},
		components: {
			normalHead,
			myCart
		}
	}
